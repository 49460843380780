<template>
  <preview :src="path"/>
</template>

<script>
import Preview from "@/pages/Preview";
import urls from "../config/api";

export default {
  name: "FilePreview",
  components: {Preview},
  computed: {
    path: state => {
      let originalPath = state.$route.params.path ? state.$route.params.path : null;
      return originalPath.indexOf('http') < 0 ? urls.backend.url  + '/' + originalPath : originalPath;
    },
  }
}
</script>
